import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function Everlong() {
  return (
    <Layout>
      <Helmet title="Everlong | Foo Fighters" />

      <h1>Everlong</h1>

      <h2>Foo Fighters</h2>

      <h4>Key: Drop D</h4>

      <h4>Tempo: 124</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>Hello</p>
          <p>I've waited here for you</p>
          <p>Everlong</p>
          <p>Tonight</p>
          <p>I throw myself into</p>
          <p>And out of the red</p>
          <p>Out of her head she sang</p>
        </Verse>
        <Verse>
          <p>Come down</p>
          <p>And waste away with me</p>
          <p>Down with me</p>
          <p>Slow how</p>
          <p>You wanted it to be</p>
          <p>I'm over my head</p>
          <p>Out of her head she sang</p>
        </Verse>
        <PreChorus>
          <p>And I wonder</p>
          <p>When I sing along with you</p>
        </PreChorus>
        <Chorus>
          <p>If everything could ever be this real forever</p>
          <p>If anything could ever be this good again</p>
          <p>The only thing I'll ever ask of you</p>
          <p>You gotta promise not to stop when I say when she sang</p>
        </Chorus>
        <Verse>
          <p>Breathe out</p>
          <p>So I can breathe you in</p>
          <p>Hold you in</p>
          <p>And now</p>
          <p>I know you've always been</p>
          <p>Out of your head</p>
          <p>Out of my head I sang</p>
        </Verse>
        <PreChorus>
          <p>And I wonder</p>
          <p>When I sing along with you</p>
        </PreChorus>
        <Chorus>
          <p>If everything could ever be this real forever</p>
          <p>If anything could ever be this good again</p>
          <p>The only thing I'll ever ask of you</p>
          <p>You gotta promise not to stop when I say when she sang</p>
        </Chorus>
        <Bridge>
          <p>And I wonder</p>
        </Bridge>
        <Chorus>
          <p>If everything could ever be this real forever</p>
          <p>If anything could ever be this good again</p>
          <p>The only thing I'll ever ask of you</p>
          <p>You gotta promise not to stop when I say when she sang</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
